const actions = {
    POST_CITY: 'POST_CITY',
    GET_ADMIN_CITY_LIST: 'GET_ADMIN_CITY_LIST',
    CITY_LIST_RESPONSE: 'CITY_LIST_RESPONSE',
    GET_CITY: 'GET_CITY',
    CITY_RESPONSE: 'CITY_RESPONSE',
    CALL_LOADER: 'CALL_LOADER',
    CALL_CITY_SUCESS: 'CALL_CITY_SUCESS',
    DELETE_CITY: 'DELETE_CITY',
    CALL_CITY_UPDATE_SUCESS:'CALL_CITY_UPDATE_SUCESS',

    POST_COUNTRY: 'POST_COUNTRY',
    GET_ADMIN_COUNTRY_LIST: 'GET_ADMIN_COUNTRY_LIST',
    COUNTRY_LIST_RESPONSE: 'COUNTRY_LIST_RESPONSE',
    GET_COUNTRY: 'GET_COUNTRY',
    COUNTRY_RESPONSE: 'COUNTRY_RESPONSE',
    CALL_COUNTRY_SUCESS: 'CALL_COUNTRY_SUCESS',
    DELETE_COUNTRY: 'DELETE_COUNTRY',
    CALL_COUNTRY_UPDATE_SUCESS:'CALL_COUNTRY_UPDATE_SUCESS',

    POST_AREA: 'POST_AREA',
    GET_ADMIN_AREA_LIST: 'GET_ADMIN_AREA_LIST',
    AREA_LIST_RESPONSE: 'AREA_LIST_RESPONSE',
    GET_AREA: 'GET_AREA',
    AREA_RESPONSE: 'AREA_RESPONSE',
    CALL_AREA_SUCESS: 'CALL_AREA_SUCESS',
    DELETE_AREA: 'DELETE_AREA',
    CALL_AREA_UPDATE_SUCESS:'CALL_AREA_UPDATE_SUCESS',

    GET_USER_COUNTRY_LIST: 'GET_USER_COUNTRY_LIST',
    USER_COUNTRY_LIST_RESPONSE: 'USER_COUNTRY_LIST_RESPONSE',

    GET_USER_CITY_LIST: 'GET_USER_CITY_LIST',
    USER_CITY_LIST_RESPONSE: 'USER_CITY_LIST_RESPONSE',
}

export default actions;