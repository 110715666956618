const actions = {
  AUTHENTICATE_USER: "AUTHENTICATE_USER",
  AUTHENTICATE_USER_SUCCESS: "AUTHENTICATE_USER_SUCCESS",
  AUTHENTICATE_USER_FAILURE: "AUTHENTICATE_USER_FAILURE",
  LOGOUT_USER: "LOGOUT_USER",
  LOGOUT_USER_FAILURE: "LOGOUT_USER_FAILURE",
  SEND_PASSWORD_RESET_LINK: "SEND_PASSWORD_RESET_LINK",
  SEND_PASSWORD_RESET_LINK_SUCCESS: "SEND_PASSWORD_RESET_LINK_SUCCESS",
  SEND_PASSWORD_RESET_LINK_FAILURE: "SEND_PASSWORD_RESET_LINK_FAILURE",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  VALIDATE_AUTH_TOKEN: "VALIDATE_AUTH_TOKEN",
  UPDATE_PROFILE_IMAGE: "UPDATE_PROFILE_IMAGE",
  UPDATE_PROFILE_IMAGE_SUCCESS: "UPDATE_PROFILE_IMAGE_SUCCESS",
  UPDATE_PROFILE_IMAGE_FAILURE: "UPDATE_PROFILE_IMAGE_FAILURE",
  CREATE_PAYMENT_ACCOUNT: "CREATE_PAYMENT_ACCOUNT",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  ADMIN_AUTHENTICATE_USER: "ADMIN_AUTHENTICATE_USER",
  ADMIN_AUTHENTICATE_USER_SUCCESS: "ADMIN_AUTHENTICATE_USER_SUCCESS",
  ADMIN_AUTHENTICATE_USER_FAILURE: "ADMIN_AUTHENTICATE_USER_FAILURE",
  SET_SHOW_ADMIN_FORGOT: "SET_SHOW_ADMIN_FORGOT",
  ADMIN_SEND_PASSWORD_RESET_LINK: "ADMIN_SEND_PASSWORD_RESET_LINK",
  ADMIN_SEND_PASSWORD_RESET_LINK_SUCCESS:
    "ADMIN_SEND_PASSWORD_RESET_LINK_SUCCESS",
  ADMIN_SEND_PASSWORD_RESET_LINK_FAILURE:
    "ADMIN_SEND_PASSWORD_RESET_LINK_FAILURE",
  ADMIN_EDIT_PROFILE: "ADMIN_EDIT_PROFILE",
  ADMIN_EDIT_PROFILE_SUCCESS: "ADMIN_EDIT_PROFILE_SUCCESS",
  ADMIN_EDIT_PROFILE_FAILURE: "ADMIN_EDIT_PROFILE_PROFILE",
  ADMIN_CHANGE_PASSWORD: "ADMIN_CHANGE_PASSWORD",
  ADMIN_CHANGE_PASSWORD_SUCCESS: "ADMIN_CHANGE_PASSWORD_SUCCESS",
  ADMIN_CHANGE_PASSWORD_FAILURE: "ADMIN_CHANGE_PASSWORD_PROFILE",
  AUTH_SINGUP_SUCCESS: "AUTH_SINGUP_SUCCESS",
  AUTH_REMOVE_OTP: "AUTH_REMOVE_OTP",

  VENDOR_AUTHENTICATE_USER: "VENDOR_AUTHENTICATE_USER",
  VENDOR_AUTHENTICATE_USER_SUCCESS: "VENDOR_AUTHENTICATE_USER_SUCCESS",
  VENDOR_AUTHENTICATE_USER_FAILURE: "VENDOR_AUTHENTICATE_USER_FAILURE",
  VENDOR_SEND_PASSWORD_RESET_LINK: "VENDOR_SEND_PASSWORD_RESET_LINK",
  VENDOR_SEND_PASSWORD_RESET_LINK_SUCCESS:
    "VENDOR_SEND_PASSWORD_RESET_LINK_SUCCESS",
  VENDOR_SEND_PASSWORD_RESET_LINK_FAILURE:
    "VENDOR_SEND_PASSWORD_RESET_LINK_FAILURE",

  VENDOR_EDIT_PROFILE: "VENDOR_EDIT_PROFILE",
  VENDOR_EDIT_PROFILE_SUCCESS: "VENDOR_EDIT_PROFILE_SUCCESS",
  VENDOR_EDIT_PROFILE_FAILURE: "VENDOR_EDIT_PROFILE_PROFILE",
  VENDOR_CHANGE_PASSWORD: "VENDOR_CHANGE_PASSWORD",
  VENDOR_CHANGE_PASSWORD_SUCCESS: "VENDOR_CHANGE_PASSWORD_SUCCESS",
  VENDOR_CHANGE_PASSWORD_FAILURE: "VENDOR_CHANGE_PASSWORD_PROFILE",

  CREATE_AUTHENTICATE_USER: "CREATE_AUTHENTICATE_USER",
  CREATE_AUTHENTICATE_USER_SUCCESS: "CREATE_AUTHENTICATE_USER_SUCCESS",
  CREATE_AUTHENTICATE_USER_FAILURE: "CREATE_AUTHENTICATE_USER_FAILURE",

  VERIFY_OTP: "VERIFY_OTP",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",

  RESEND_OTP: "RESEND_OTP",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "RESEND_OTP_FAILURE",

  SET_VENDOR_LANGUAGE_DETAILS: "SET_VENDOR_LANGUAGE_DETAILS",

  VENDOR_IMAGE_REMOVED: "VENDOR_IMAGE_REMOVED",
  VENDOR_IMAGE_REMOVED_SUCCESS: "VENDOR_IMAGE_REMOVED_SUCCESS",
  VENDOR_IMAGE_REMOVED_FAILURE: "VENDOR_IMAGE_REMOVED_FAILURE",

  VENDOR_EDIT_PROFILE_STATUS: "VENDOR_EDIT_PROFILE_STATUS",

  GET_APP_LANGUAGE_LIST: "GET_APP_LANGUAGE_LIST",
  GET_APP_LANGUAGE_LIST_SUCCESS: "GET_APP_LANGUAGE_LIST_SUCCESS",
  GET_APP_LANGUAGE_LIST_FAILURE: "GET_APP_LANGUAGE_LIST_FAILURE",
  SET_SELECTED_LANGUAGE: "SET_SELECTED_LANGUAGE",

  GET_USER_BOOKING_LIST: "GET_USER_BOOKING_LIST",
  GET_USER_BOOKING_LIST_SUCCESS: "GET_USER_BOOKING_LIST_SUCCESS",
  GET_USER_BOOKING_LIST_FAILURE: "GET_USER_BOOKING_LIST_FAILURE",

  GET_USER_FAV_LIST: "GET_USER_FAV_LIST",
  GET_USER_FAV_LIST_SUCCESS: "GET_USER_FAV_LIST_SUCCESS",
  GET_USER_FAV_LIST_FAILURE: "GET_USER_FAV_LIST_FAILURE",

  SET_LAST_PATH: "SET_LAST_PATH",
  CREATE_PARTNER_ACCOUNT: "CREATE_PARTNER_ACCOUNT",
  CREATE_PARTNER_ACCOUNT_RESPONSE: "CREATE_PARTNER_ACCOUNT_RESPONSE",
  CREATE_PARTNER_ACCOUNT_FAILURE: "CREATE_PARTNER_ACCOUNT_FAILURE",

  GET_USER_BOOKING_COUNT: "GET_USER_BOOKING_COUNT",
  GET_USER_BOOKING_LISTCOUNT_SUCCESS: "GET_USER_BOOKING_LISTCOUNT_SUCCESS",

  USER_BOOKING_CANCEL: "USER_BOOKING_CANCEL",
  USER_BOOKING_REORDER: "USER_BOOKING_REORDER",
  GET_VENDORDETAIL_SUCCESS: "GET_VENDORDETAIL_SUCCESS",

  VALIDATE_PARTNER_EMAIL: "VALIDATE_PARTNER_EMAIL",
  VALIDATE_PARTNER_EMAIL_SUCCESS: "VALIDATE_PARTNER_EMAIL_SUCCESS",
  VALIDATE_PARTNER_EMAIL_FAILURE: "VALIDATE_PARTNER_EMAIL_FAILURE",
  FINAL_PAYMENT_SUCCESS_CALL: "FINAL_PAYMENT_SUCCESS_CALL",

  VERIFY_MAILID: "VERIFY_MAILID",
  VERIFY_MAILID_SUCCESS: "VERIFY_MAILID_SUCCESS",
  VERIFY_MAILID_FAILURE: "VERIFY_MAILID_FAILURE",

  GET_SETTINGS: "GET_SETTINGS",
  GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",

  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",

  RESET_FORGOT_PASSWORD: "RESET_FORGOT_PASSWORD",
  RESET_FORGOT_PASSWORD_SUCCESS: "RESET_FORGOT_PASSWORD_SUCCESS",
  RESET_FORGOT_PASSWORD_FAILURE: "RESET_FORGOT_PASSWORD_FAILURE",

  RESET_VENDOR_FORGOT_PASSWORD: "RESET_VENDOR_FORGOT_PASSWORD"
};

export default actions;
