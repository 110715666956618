import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "helpers/axiosClient";
import { getLocaleMessages } from "redux/helper";
import { message } from "antd";

export function* ratingDetail(params) {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "GET_ALL_USE_REVIEW" }))
    );
    yield put({
      type: actions.GET_SALOON_RATING_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_SALOON_DETAILS_FAILURE });
  }
}

export function* addAddressDetail(params) {
  try {
    const response = yield call(() =>
      postRequest(
        getLocaleMessages({ id: "BOOKING_ADDRESS_CREATE" }),
        params.payload
      )
    );
    message.success(getLocaleMessages({ id: "Address Created Successfully!" }));

    yield put({
      type: actions.GET_ADD_ADDRESS_SUCCESS,
      payload: response.data.data,
    });
    params.callBackAction(response.status);

    let obje = {};
    obje["customerid"] = params.payload.userid;
    yield put({
      type: actions.GETALL_ADDRESS,
      payload: obje,
    });
  } catch (error) {
    message.error(getLocaleMessages({ id: "This is a error message" }));
    yield put({ type: actions.GET_SALOON_DETAILS_FAILURE });
  }
}

export function* editAddressDetail(params) {
  try {
    const response = yield call(() =>
      putRequest(
        getLocaleMessages({ id: "BOOKING_ADDRESS_UPDATE" }),
        params.payload
      )
    );

    yield put({
      type: actions.GET_UPDATE_ADDRESS_SUCCESS,
      payload: response.data.data,
    });

    params.callBackAction(response.status);

    let obje = {};
    obje["customerid"] = params.payload.userid;
    yield put({
      type: actions.GETALL_ADDRESS,
      payload: obje,
    });
  } catch (error) {
    yield put({ type: actions.GET_SALOON_DETAILS_FAILURE });
  }
}

export function* ratingDetailUpdate(params) {
  try {
    const response = yield call(() =>
      putRequest(
        getLocaleMessages({ id: "USER_REVIEW_UPDATE" }),
        params.payload
      )
    );
    params.callBackAction(response);
  } catch (error) {}
}

export function* deleteAddressDetail(params) {
  try {
    const response = yield call(() =>
      deleteRequest(
        `${getLocaleMessages({ id: "BOOKING_ADDRESS_REMOVE" })}=${params.id}`
      )
    );
    yield put({
      type: actions.DELETE_ADDRESS_SUCCESS,
      payload: response.data.data,
    });
    params.callBackAction(response.status);
    let obje = {};
    obje["customerid"] = params.userid;
    yield put({
      type: actions.GETALL_ADDRESS,
      payload: obje,
    });
  } catch (error) {}
}

export function* getallAddressDetail(params) {
  try {
    const response = yield call(() =>
      getRequest(
        getLocaleMessages({ id: "GET_ALL_BOOKING_ADDRESS" }) + params.customerid
      )
    );
    yield put({
      type: actions.GET_ADDRESS_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ADDRESS_FAILURE });
  }
}

export function* getallCountryDetail(params) {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "GET_ALL_COUNTRY" }))
    );
    yield put({
      type: actions.GET_COUNTRY_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ADDRESS_FAILURE });
  }
}

export function* getallCityDetail(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({
          id: "GET_ALL_USER_CITY",
        })}=${params.countryid}`
      )
    );
    yield put({
      type: actions.GET_CITY_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ADDRESS_FAILURE });
  }
}

export function* getTotalCityDetail(params) {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "GET_ALL_CITY" }))
    );
    yield put({
      type: actions.GET_ALL_CITY_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ALL_CITY_LIST_FAILURE });
  }
}

export function* getEditDetail(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "GET_USER_BOOKING_ADDRESS" })}=` +
          params.id.id
      )
    );
    yield put({
      type: actions.EDITADDRESS_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_ADDRESS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SALOON_RATING_DETAILS, ratingDetail),
    takeEvery(actions.SALOON_RATING_REVIEW_UPDATE, ratingDetailUpdate),
    takeEvery(actions.ADD_ADDRESS, addAddressDetail),
    takeEvery(actions.UPDATE_ADDRESS, editAddressDetail),
    takeEvery(actions.DELETE_ADDRESS, deleteAddressDetail),
    takeEvery(actions.GETALL_ADDRESS, getallAddressDetail),
    takeEvery(actions.GETALL_COUNTRY, getallCountryDetail),
    takeEvery(actions.EDIT_ADDRESS, getEditDetail),
    takeEvery(actions.GET_ALL_CITY_LIST, getTotalCityDetail),
  ]);
}
