import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { getRequest, putRequest } from "helpers/axiosClient";
import swal from "sweetalert";
import { getLocaleMessages } from "redux/helper";

export function* getCommissionList() {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "getCommissionList" }))
    );
    yield put({
      type: actions.GET_COMMISSION_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actions.GET_COMMISSION_LIST_FAILURE,
    });
  }
}

export function* newCommissionCreate(params) {
  try {
    const response = yield call(() =>
      putRequest(
        `${getLocaleMessages({ id: "newCommissionCreate" })}`,
        params.payload
      )
    );
    yield put({
      type: actions.NEW_COMMISSION_SUCCESS,
      payload: response.data.data,
    });
    if (response?.data) {
      params.callBackAction(response);
    }
    swal({
      title: "Success",
      text: params?.notificationText
        ? params?.notificationText
        : getLocaleMessages({ id: "Commision Created Successfully" }),
      icon: "success",
      button: true,
    });
  } catch (error) {
    yield put({
      type: actions.NEW_COMMISSION_FAILURE,
    });
    swal({
      title: "Failed",
      text: getLocaleMessages({ id: "Commision Created Failed" }),
      icon: "error",
      button: true,
    });
  }
}
export default function* ratingSaga() {
  yield all([
    takeEvery(actions.GET_COMMISSION_LIST, getCommissionList),
    takeEvery(actions.NEW_COMMISSION, newCommissionCreate),
  ]);
}
