import React from "react";
import { Modal, Input, Form, Button } from "antd";
import { getLocaleMessages } from "redux/helper";

const ForgotModal = (props) => {
  const { visible, onCancel, onLoginForgot, onFinish, loader } = props;
  return (
    <Modal
      title={false}
      open={visible}
      onCancel={onCancel}
      centered
      footer={false}
      className="modal-ui-1"
      width="100%"
      destroyOnClose
    >
      <div className="modal-body-ui">
        <h2>{getLocaleMessages({ id: "Forgot Password" })}</h2>
        <Form name="normal_login" className="login-form" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: getLocaleMessages({ id: "common.email.error" }),
              },
              {
                type: "email",
                message: getLocaleMessages({ id: "Invalid email" }),
              },
            ]}
          >
            <Input placeholder={getLocaleMessages({ id: "Email" })} />
          </Form.Item>
          <div className="text-right forgotLink">
            <div onClick={() => onLoginForgot({ login: true, forgot: false })}>
              {getLocaleMessages({ id: "Back to login" })}
            </div>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loader}
              disabled={loader}
            >
              {getLocaleMessages({ id: "Forgot Password" })}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ForgotModal;
