const actions = {
  GET_ADMINISTRATOR: "GET_ADMINISTRATOR",
  GET_ONLY_ADMINISTRATOR: "GET_ONLY_ADMINISTRATOR",
  GET_ONLY_ADMINISTRATOR_RESPONSE: "GET_ONLY_ADMINISTRATOR_RESPONSE",
  GET_ONLY_SINGLEADMINISTRATOR_RESPONSE:
    "GET_ONLY_SINGLEADMINISTRATOR_RESPONSE",
  GET_ADMINISTRATOR_RESPONSE: "GET_ADMINISTRATOR_RESPONSE",
  CREATE_ADMINISTRATOR: "CREATE_ADMINISTRATOR",
  CREATE_ADMINISTRATOR_RES: "CREATE_ADMINISTRATOR_RES",
  CALL_ADMIN_LOADER: "CALL_ADMIN_LOADER",

  CREATE_ADMINISTRATOR_RESPONSE: "CREATE_ADMINISTRATOR_RESPONSE",
  UPDATE_ADMINISTRATOR: "UPDATE_ADMINISTRATOR",
  UPDATE_ADMINISTRATOR_RESPONSE: "UPDATE_ADMINISTRATOR_RESPONSE",
  DELETE_ADMINISTRATOR: "DELETE_ADMINISTRATOR",
  GET_SINGLE_ADMINISTRATOR: "GET_SINGLE_ADMINISTRATOR",
  DELETE_ADMINISTRATOR_RESPONSE: "DELETE_ADMINISTRATOR_RESPONSE",
  UPDATE_PARENT_CHECK: "UPDATE_PARENT_CHECK",
  UPDATE_CHILD_CHECK: "UPDATE_CHILD_CHECK",
  ASSIGNED_ADMINISTRATOR_RESPONSE: "ASSIGNED_ADMINISTRATOR_RESPONSE",
  GET_ASSIGNED_ADMINISTRATOR: "GET_ASSIGNED_ADMINISTRATOR",
  ADMINISTRATORNAME: "ADMINISTRATORNAME",
  ADMINISTRATOR_STATUS: "ADMINISTRATOR_STATUS",
  UPDATE_RESPONSE: "UPDATE_RESPONSE",
  ADMINISTRATOR_REDIRECT: "ADMINISTRATOR_REDIRECT",
  FIRSTNAME: "FIRSTNAME",
  LASTNAME: "LASTNAME",
  MOBILE: "MOBILE",
  CONFIRM_PASSWORD: "CONFIRM_PASSWORD",
  PASSWORD: "PASSWORD",
  USERNAME: "USERNAME",
  EMAIL: "EMAIL",
  ROLE_TYPE: "ROLE_TYPE",
  GET_ASSIGNED_ROLES: "GET_ASSIGNED_ROLES",
  ASSIGNED_ROLES_RESPONSE: "ASSIGNED_ROLES_RESPONSE",
  GET_SINGLE_ADMINISTRATOR: "GET_SINGLE_ADMINISTRATOR",
  GET_ONLY_SINGLE_ADMINISTRATOR_RESPONSE:
    "GET_ONLY_SINGLE_ADMINISTRATOR_RESPONSE",
  GET_ACTIVE_ROLES: "GET_ACTIVE_ROLES",
  ACTIVE_ROLES_RESPONSE: "ACTIVE_ROLES_RESPONSE",
  ASSIGN_SELECTED_ROW: "ASSIGN_SELECTED_ROW",
  ASSIGN_SERCHED_DATA: "ASSIGN_SERCHED_DATA",
  ASSIGN_CMS_LANGUAGE: "ASSIGN_CMS_LANGUAGE",
  ASSIGN_CATEGORY_DATA: "ASSIGN_CATEGORY_DATA",

  GET_ACTIVITY_LOG: "GET_ACTIVITY_LOG",
  GET_ACTIVITY_LOG_RESPONSE: "GET_ACTIVITY_LOG_RESPONSE",
  DELETE_LOG: "DELETE_LOG",
  CALL_LOADER: "CALL_LOADER",
  SET_LAST_PAGE: "SET_LAST_PAGE",

};

export default actions;
