import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import {
  getRequest,
  //   patchRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "helpers/axiosClient";
import swal from "sweetalert";
import { getLocalData, getLocaleMessages } from "redux/helper";
import { message } from "antd";
//import { getLocaleMessages } from 'helpers/axiosClient';
// import { getLocalData } from 'redux/helper';

export function* getAdministratorData(params) {
  try {
    const response = yield call(() =>
      postRequest(getLocaleMessages({ id: "ADMIN_GETALL_ADMINISTRATOR" }))
    );
    yield put({
      type: actions.GET_ONLY_ADMINISTRATOR_RESPONSE,
      payload: response.data.data,
    });
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Administrator Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
  }
}

export function* getSingleAdministratorData(params) {
  try {
    var id = parseInt(params.payload);
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "ADMIN_GET_ADMINISTRATOR_BYID" })}=` + id
      )
    );
    yield put({
      type: actions.GET_ONLY_SINGLE_ADMINISTRATOR_RESPONSE,
      payload: response.data.data.data[0],
    });
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Administrator Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
  }
}

export function* getAssignedAdministratorData() {
  try {
    yield put({
      type: actions.CALL_ADMIN_LOADER,
    });
    const response = yield call(() =>
      postRequest(getLocaleMessages({ id: "ADMIN_GETALL_ADMINISTRATOR" }))
    );
    yield put({
      type: actions.ASSIGNED_ADMINISTRATOR_RESPONSE,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actions.CALL_ADMIN_LOADER,
    });
    swal({
      title: getLocaleMessages({ id: "Administration" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
  }
}

export function* createAdministratorData(params) {
  try {
    if (params) {
      const response = yield call(() =>
        postRequest(
          getLocaleMessages({ id: "ADMIN_ADMINISTARTOR_CREATE" }),
          params.payload
        )
      );
      if (response.data) {
        swal({
          title: getLocaleMessages({ id: "Administration Created" }),
          icon: "success",
          button: false,
          timer: 1500,
        });
      }

      yield put({
        type: actions.ADMINISTRATOR_REDIRECT,
      });
    }
  } catch (error) {
    let newErrorCustomization = "";
    if (
      error.response &&
      error.response.data &&
      error.response.data.name === "ValidationError"
    ) {
      error.response &&
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.map((errorMessage) => {
          newErrorCustomization = `${newErrorCustomization}${errorMessage["message"]}`;
          return errorMessage;
        });
    } else {
      newErrorCustomization =
        error.response && error.response.data && error.response.data.message;
    }
    message.error(newErrorCustomization);
  }
}

export function* updateAdministratorData(params) {
  try {
    if (params) {
      const response = yield call(() =>
        postRequest(
          getLocaleMessages({ id: "ADMIN_ADMINISTRATOR_UPDATE" }),
          params.payload
        )
      );
      if (response.data) {
        swal({
          title: getLocaleMessages({ id: "Administration Updated" }),
          icon: "success",
          button: false,
          timer: 1500,
        });
        yield put({
          type: actions.ADMINISTRATOR_REDIRECT,
          payload: response.data.data,
        });
      }
    }
  } catch (error) {
    let newErrorCustomization = "";
    swal({
      title: getLocaleMessages({ id: "Administrator Error" }),
      text: newErrorCustomization,
      icon: "error",
      button: false,
    });
  }
}

export function* deleteAdministratordata(params) {
  try {
    const response = yield call(() =>
      deleteRequest(
        `${getLocaleMessages({ id: "ADMIN_ADMINISTARATOR_DELETE" })}=${
          params.id
        }`
      )
    );
    if (response.data.code == 200) {
      yield put({
        type: actions.GET_ASSIGNED_ADMINISTRATOR,
      });

      swal({
        title: getLocaleMessages({ id: "Administration Delete" }),
        text: `${response.data.name}`,
        icon: "success",
        button: false,
        timer: 1500,
      });
    }
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Administrator Assigned!" }),
      text: getLocaleMessages({
        id: "Administrator is Assigned to Admin User's",
      }),
      type: "warning",
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Okay!",
      button: false,
      timer: 1500,
    });
  }
}

export function* getAssignedRolesData(params) {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "ADMIN_GET_ROLE" }), params.payload)
    );
    yield put({
      type: actions.ACTIVE_ROLES_RESPONSE,
      payload: response.data.data,
    });
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Roles Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
  }
}

export function* getActivityLogData(params) {
  try {
    yield put({
      type: actions.CALL_LOADER,
    });
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "ADMIN_GETALL_ACTIVITYLOG" })}=${
          params.pageSize
        }&page=${params.page}`
      )
    );
    yield put({
      type: actions.GET_ACTIVITY_LOG_RESPONSE,
      payload: response.data.data,
    });
    yield put({
      type: actions.SET_LAST_PAGE,
      value: params.page,
    });
  } catch (error) {
    yield put({
      type: actions.CALL_LOADER,
    });
    yield put({
      type: actions.GET_ACTIVITY_LOG_RESPONSE,
      payload: [],
    });
    yield put({
      type: actions.SET_LAST_PAGE,
      value: 1,
    });
    swal({
      title: getLocaleMessages({ id: "Logs Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
  }
}

export function* deleteActivityLogData(params) {
  try {
    yield put({
      type: actions.CALL_LOADER,
    });
    const response = yield call(() =>
      deleteRequest(
        `${getLocaleMessages({ id: "ADMIN_DELETE_ACTIVITYLOG" })}=${params.id}`
      )
    );
    message.success(
      getLocaleMessages({ id: "Log has been deleted successfully" })
    );
    var lastpage = localStorage.getItem("lastpage");
    var page = 1;
    if (lastpage) {
      page = lastpage;
    } else {
      page = page;
    }
    yield put({
      type: actions.GET_ACTIVITY_LOG,
      page: page,
    });
    yield put({
      type: actions.CALL_LOADER,
    });
  } catch (error) {
    yield put({
      type: actions.CALL_LOADER,
    });
    swal({
      title: getLocaleMessages({ id: "Logs delete Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
  }
}

export default function* AdministratorSaga() {
  yield all([
    takeEvery(actions.GET_ONLY_ADMINISTRATOR, getAdministratorData),
    takeEvery(actions.GET_SINGLE_ADMINISTRATOR, getSingleAdministratorData),
    takeEvery(actions.CREATE_ADMINISTRATOR, createAdministratorData),
    takeEvery(actions.UPDATE_ADMINISTRATOR, updateAdministratorData),
    takeEvery(actions.DELETE_ADMINISTRATOR, deleteAdministratordata),
    takeEvery(actions.GET_ASSIGNED_ADMINISTRATOR, getAssignedAdministratorData),
    takeEvery(actions.GET_ACTIVE_ROLES, getAssignedRolesData),
    takeEvery(actions.GET_ACTIVITY_LOG, getActivityLogData),
    takeEvery(actions.DELETE_LOG, deleteActivityLogData),
  ]);
}
