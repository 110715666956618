const actions = {
  GET_SALOON_RATING_DETAILS: "GET_SALOON_RATING_DETAILS",
  SALOON_RATING_REVIEW_UPDATE: "SALOON_RATING_REVIEW_UPDATE",
  GET_SALOON_RATING_DETAILS_SUCCESS: "GET_SALOON_RATING_DETAILS_SUCCESS",
  GET_SALOON_RATING_DETAILS_FAILURE: "GET_SALOON_RATING_DETAILS_FAILURE",
  ADD_ADDRESS: "ADD_ADDRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  GET_ADD_ADDRESS_SUCCESS: "GET_ADD_ADDRESS_SUCCESS",
  GET_ADD_ADDRESS_FAILURE: "GET_ADD_ADDRESS_FAILURE",
  GET_UPDATE_ADDRESS_SUCCESS: "GET_UPDATE_ADDRESS_SUCCESS",
  GET_UPDATE_ADDRESS_FAILURE: "GET_UPDATE_ADDRESS_FAILURE",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  GET_ADDRESS_DETAILS_SUCCESS: "GET_ADDRESS_DETAILS_SUCCESS",
  GET_ADDRESS_DETAILS_FAILURE: "GET_ADDRESS_DETAILS_FAILURE",
  GETALL_ADDRESS: "GETALL_ADDRESS",
  GETALL_COUNTRY: "GETALL_COUNTRY",
  GET_COUNTRY_DETAILS_SUCCESS: "GET_COUNTRY_DETAILS_SUCCESS",
  GETALL_CITY: "GETALL_CITY",
  GET_CITY_DETAILS_SUCCESS: "GET_CITY_DETAILS_SUCCESS",
  GET_ADDRESS_FAILURE: "GET_ADDRESS_FAILURE",
  EDIT_ADDRESS: "EDIT_ADDRESS",
  EDITADDRESS_DETAILS_SUCCESS: "EDITADDRESS_DETAILS_SUCCESS",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  GET_ALL_CITY_LIST: "GET_ALL_CITY_LIST",
  GET_ALL_CITY_LIST_SUCCESS: "GET_ALL_CITY_LIST_SUCCESS",
  GET_ALL_CITY_LIST_FAILURE: "GET_ALL_CITY_LIST_FAILURE",
};

export default actions;
