const actions = {
  UPDATE_SMTP: "UPDATE_SMTP",
  UPDATE_SMTP_RESPONSE: "UPDATE_SMTP_RESPONSE",
  GET_SMTP_DETAILS: "GET_SMTP_DETAILS",
  GET_SMTP_DETAILS_RESPONSE: "GET_SMTP_DETAILS_RESPONSE",

  GET_SMS_DETAILS: "GET_SMS_DETAILS",
  GET_SMS_DETAILS_RESPONSE: "GET_SMS_DETAILS_RESPONSE",
  UPDATE_SMS: "UPDATE_SMS",
  UPDATE_SMS_RESPONSE: "UPDATE_SMS_RESPONSE",

  GET_SOCIAL_DETAILS: "GET_SOCIAL_DETAILS",
  GET_SOCIAL_DETAILS_RESPONSE: "GET_SOCIAL_DETAILS_RESPONSE",
  UPDATE_SOCIAL_MEDIA: "UPDATE_SOCIAL_MEDIA",
  UPDATE_SOCIAL_MEDIA_RESPONSE: "UPDATE_SOCIAL_MEDIA_RESPONSE",

  UPDATE_CONFIG: "UPDATE_CONFIG",
  GET_APPCONFIG: "GET_APPCONFIG",
  GET_APPCONFIG_RESPONSE: "GET_APPCONFIG_RESPONSE",
  UPLOAD_SITEIMAGE_RESPONSE: "UPLOAD_SITEIMAGE_RESPONSE",
  UPLOAD_SITEIMG: "UPLOAD_SITEIMG",
  UPLOAD_SITEIMG_RESPONSE: "UPLOAD_SITEIMG_RESPONSE",

  SHOW_LOADER: "SHOW_LOADER",
  SAVE_IMAGE_PATH: "SAVE_IMAGE_PATH",
  UPLOAD_COMMONIMG: "UPLOAD_COMMONIMG",
  CREATE_SALOON_NUMBER: "CREATE_SALOON_NUMBER",
  CREATE_PARTNER_NUMBER: "CREATE_PARTNER_NUMBER",
};

export default actions;
