import React from "react";
import { Modal, Form, Input, Checkbox, Button } from "antd";
import { FacebookFilled, GoogleOutlined } from "@ant-design/icons";
import { getLocaleMessages } from "redux/helper";
import { NavLink } from "react-router-dom";

const signupModal = (props) => {
  const { visible, onFinish, onCancel, LoginSignup, loader } = props;
  return (
    <Modal
      footer={false}
      title={false}
      className="modal-ui-1"
      width="100%"
      open={visible}
      onCancel={onCancel}
      destroyOnClose
    >
      <div className="modal-body-ui">
        <h2>{getLocaleMessages({ id: "Sign up" })}</h2>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
          initialValues={{
            mobile_number: {
              countrycode: "+966",
            },
          }}
        >
          <Form.Item
            name="firstname"
            rules={[
              {
                required: true,
                whitespace: true,
                message: `${getLocaleMessages({
                  id: "Please input your",
                })} ${getLocaleMessages({
                  id: "First Name",
                })} !`,
              },
            ]}
          >
            <Input
              placeholder={getLocaleMessages({ id: "placeholder.firstName" })}
            />
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[
              {
                required: true,
                whitespace: true,
                message: `${getLocaleMessages({
                  id: "Please input your",
                })} ${getLocaleMessages({
                  id: "Last Name",
                })} !`,
              },
            ]}
          >
            <Input
              placeholder={getLocaleMessages({ id: "placeholder.lastName" })}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                whitespace: true,
                message: `${getLocaleMessages({
                  id: "Please input your",
                })} ${getLocaleMessages({
                  id: "Email",
                })} !`,
              },
              {
                type: "email",
                whitespace: true,
                message: getLocaleMessages({ id: "Invalid email" }),
              },
            ]}
          >
            <Input placeholder={getLocaleMessages({ id: "Email" })} />
          </Form.Item>
          <Form.Item name="mobile_number">
            <Input.Group compact>
              <Form.Item name={["mobile_number", "countrycode"]} noStyle>
                <Input
                  style={{ width: "35%" }}
                  readOnly={true}
                  addonBefore={
                    <img
                      src={require("assets/img/sa_flag_lamsat.png")}
                      width="20px"
                      alt={"logo"}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                noStyle
                name={["mobile_number", "contactnumber"]}
                rules={[
                  {
                    required: true,
                    message: `${getLocaleMessages({
                      id: "Contact number",
                    })} ${getLocaleMessages({ id: "is required" })}`,
                  },
                ]}
              >
                <Input
                  style={{ width: "65%" }}
                  placeholder={getLocaleMessages({ id: "Phone Number" })}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                whitespace: true,
                message: getLocaleMessages({
                  id: "Please input your Password!",
                }),
              },
              {
                min: 6,
                message: getLocaleMessages({
                  id: "Password must be minimum 6 characters.",
                }),
              },
              {
                max: 16,
                message: getLocaleMessages({
                  id: "Password can be maximum 16 characters.",
                }),
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder={getLocaleMessages({ id: "Password" })}
            />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            validateTrigger={["onSubmit"]}
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject("Accept the condition"),
              },
            ]}
          >
            <Checkbox>
              {getLocaleMessages({ id: "I Agree" })}
              <a className="login-form-forgot" href="">
                {getLocaleMessages({ id: "Terms and Conditions" })}
              </a>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loader}
            >
              {getLocaleMessages({ id: "Sign Up" })}
            </Button>
          </Form.Item>
        </Form>

        <span className="or">({getLocaleMessages({ id: "OR" })})</span>
        <div className="contactsocial">
          <span className="btns">
            <FacebookFilled />
          </span>
          <span className="btns">
            <GoogleOutlined />
          </span>
        </div>
        <p className="new">
          {getLocaleMessages({ id: "Already have an account" })}?{" "}
          <span
            onClick={() =>
              loader ? "" : LoginSignup({ signup: false, login: true })
            }
          >
            {getLocaleMessages({ id: "label.signIn" })}
          </span>{" "}
        </p>
        <div className="become-partner">
          <h2>{getLocaleMessages({ id: "Become a partner" })}</h2>
          <p className="new">
            {getLocaleMessages({ id: "Manage your business with Bookime by" })}{" "}
            <NavLink to={"/partner-register"}>
              <span>
                {" "}
                {getLocaleMessages({ id: "signing up as a professional" })}
              </span>{" "}
            </NavLink>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default signupModal;
