const actions = {
  CREATE_CUSTOMER: "CREATE_CUSTOMER",
  GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
  CUSTOMER_LIST_RESPONSE: "CUSTOMER_LIST_RESPONSE",
  GET_CUSTOMER: "GET_CUSTOMER",
  CUSTOMER_RESPONSE: "CUSTOMER_RESPONSE",
  CALL_LOADER: "CALL_LOADER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  CHANGE_CUSTOMER_PASSWORD: "CHANGE_CUSTOMER_PASSWORD",
};

export default actions;
