import React, { useEffect } from "react";
import { Layout, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/app/actions";
import { NavLink } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { store, history } from "redux/store";
import {
  SettingOutlined,
  ProfileOutlined,
  CreditCardOutlined,
  ScheduleOutlined,
  UserOutlined,
  StarOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { getLocaleMessages } from "redux/helper";
const { Sider } = Layout;

const VendorSidebar = () => {
  const { currentKey } = useSelector((state) => state.App),
    dispatch = useDispatch();

  useEffect(() => {
    store.dispatch({
      type: actions.CHANGE_CURRENT_MENU,
      payload: [
        history.location.pathname.toLowerCase().substring(1).replace("/", "_"),
      ],
    });
  }, [history.location.pathname]);

  const handleClick = (changedKey) => {
    dispatch({ type: actions.CHANGE_CURRENT_MENU, payload: [changedKey.key] });
  };

  return (
    <>
      <Sider className="sidebar vendor-sidebar" width={250}>
        <StickyBox offsetTop={80} offsetBottom={20}>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={currentKey}
            onClick={handleClick}
          >
            <Menu.Item key="vendor_dashboard">
              <NavLink to={"/vendor/dashboard"}>
                {" "}
                <SettingOutlined />
                {getLocaleMessages({ id: "Dashboard" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_calendar">
              <NavLink to={"/vendor/calendar"}>
                {" "}
                <UserOutlined />
                {getLocaleMessages({ id: "Calendar" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_booking">
              <NavLink to={"/vendor/booking"}>
                {" "}
                <CreditCardOutlined /> {getLocaleMessages({ id: "Bookings" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_services">
              <NavLink to={"/vendor/services"}>
                {" "}
                <ScheduleOutlined />
                {getLocaleMessages({ id: "Menu" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_staff">
              <NavLink to={"/vendor/staff"}>
                {" "}
                <UserOutlined />
                {getLocaleMessages({ id: "Staff" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_voucher">
              <NavLink to={"/vendor/voucher"}>
                {" "}
                <CreditCardOutlined /> {getLocaleMessages({ id: "Voucher" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_ratings">
              <NavLink to={"/vendor/ratings"}>
                {" "}
                <StarOutlined />
                {getLocaleMessages({ id: "Rating & Review" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_report">
              <NavLink to={"/vendor/report"}>
                {" "}
                <InteractionOutlined />
                {getLocaleMessages({ id: "Report" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="vendor_profile">
              <NavLink to={"/vendor/profile"}>
                {" "}
                <ProfileOutlined />
                {getLocaleMessages({ id: "Profile" })}
              </NavLink>
            </Menu.Item>
          </Menu>
        </StickyBox>
      </Sider>
    </>
  );
};

export default VendorSidebar;
