import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import swal from "sweetalert";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "helpers/axiosClient";
import { message } from "antd";
import { getLocalData, getLocaleMessages } from "redux/helper";

export function* getvendorTimeslot(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "getAllvendorTimeslot" })}=` +
          params.vendorid
      )
    );
    yield put({
      type: actions.GET_VENDOR_TIME_LIST_RES,
      payload: response.data.data,
    });
    if (response) {
      params.callBackAction(response);
    }
  } catch (error) {
    message.error(getLocaleMessages({ id: "category.list.error" }));
  }
}

export function* putvendorTimeslot(params) {
  try {
    const response = yield call(() =>
      putRequest(getLocaleMessages({ id: "putVendorTimeslot" }), params)
    );
    if (response.data) {
      params.callBackAction(true);
    }
    yield put({
      type: actions.GET_VENDOR_TIME_LIST,
      vendorid: params.vendorid,
      callBackAction: () => {},
    });
    swal({
      text: getLocaleMessages({ id: "Timeslot Updated successfully" }), //getLocaleMessages({ id: "Timeslot Updated successfully" }),
      title: "Update", //getLocaleMessages({ id: "Update" }),
      type: "info",
      button: true,
      timer: 1500,
    });
  } catch (error) {
    message.error(getLocaleMessages({ id: "category.list.error" }));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_VENDOR_TIME_LIST, getvendorTimeslot),
    takeEvery(actions.UPDATE_VENDOR_TIME, putvendorTimeslot),
  ]);
}
