const actions = {
  CREATE_BOOKING: "CREATE_BOOKING",
  GET_BOOKING_LIST: "GET_BOOKING_LIST",
  GET_BOOKING_LIST: "GET_BOOKING_LIST",
  GET_BOOKING_LIST_VENDOR: "GET_BOOKING_LIST_VENDOR",
  GET_BOOKING_LIST_VENDOR_REPORT: "GET_BOOKING_LIST_VENDOR_REPORT",
  GET_BOOKING_LIST_VENDOR_REPORT_RESPONSE:
    "GET_BOOKING_LIST_VENDOR_REPORT_RESPONSE",
  CALL_BOOKINGLIST_LOADER: "CALL_BOOKINGLIST_LOADER",
  GET_BOOKING_LIST_REPORT_ADMIN: "GET_BOOKING_LIST_REPORT_ADMIN",
  GET_BOOKING_LIST_REPORT_ADMIN_OUTPUT: "GET_BOOKING_LIST_REPORT_ADMIN_OUTPUT",
  BOOKING_LIST_RESPONSE: "BOOKING_LIST_RESPONSE",
  GET_BOOKING: "GET_BOOKING",
  BOOKING_RESPONSE: "BOOKING_RESPONSE",
  CALL_LOADER: "CALL_LOADER",
  DELETE_BOOKING: "DELETE_BOOKING",
  GET_VENDOR_BOOKING_LIST: "GET_VENDOR_BOOKING_LIST",
  GET_VENDOR_BOOKING_LIST_RES: "GET_VENDOR_BOOKING_LIST_RES",
  UPDATE_VENDOR_BOOKING_STATUS: "UPDATE_VENDOR_BOOKING_STATUS",
  UPDATE_VENDOR_PAYMENT_STATUS: "UPDATE_VENDOR_PAYMENT_STATUS",
  UPDATE_ADMIN_BOOKING_STATUS: "UPDATE_ADMIN_BOOKING_STATUS",
  UPDATE_VENDOR_BOOKING_STATUS_RES: "UPDATE_VENDOR_BOOKING_STATUS_RES",
  GET_VENDOR_LIST: "GET_VENDOR_LIST",
  VENDOR_LIST_RESPONSE: "VENDOR_LIST_RESPONSE",
  GET_STAFF_LIST: "GET_STAFF_LIST",
  GET_STAFF_LIST_RESPONSE: "GET_STAFF_LIST_RESPONSE",
  GET_VENDOR_REPORT_FILTER_LIST: "GET_VENDOR_REPORT_FILTER_LIST",
  GET_ADMIN_REPORT_FILTER_LIST: "GET_ADMIN_REPORT_FILTER_LIST",
};

export default actions;
