const actions = {
    GET_ADMIN_TIME_LIST : 'GET_ADMIN_TIME_LIST',
    GET_ADMIN_TIME_LIST_FAILURE : 'GET_ADMIN_TIME_LIST_FAILURE',
    GET_ADMIN_TIME_LIST_RES : 'GET_ADMIN_TIME_LIST_RES',
    UPDATE_ADMIN_TIME : 'UPDATE_ADMIN_TIME',
    UPDATE_ADMIN_TIME_RES : 'UPDATE_ADMIN_TIME_RES',
    GET_ADMIN_VENDOR_LIST : 'GET_ADMIN_VENDOR_LIST',
    GET_ADMIN_VENDOR_LIST_RES : 'GET_ADMIN_VENDOR_LIST_RES',
    UPDATE_ADMIN_TIME_FAILURE: 'UPDATE_ADMIN_TIME_FAILURE'

    
}

export default actions;