const actions = {
    POST_ENQUIRY: 'POST_ENQUIRY',
    GET_ADMIN_ENQUIRY_LIST: 'GET_ADMIN_ENQUIRY_LIST',
    ENQUIRY_LIST_RESPONSE: 'ENQUIRY_LIST_RESPONSE',
    GET_ENQUIRY: 'GET_ENQUIRY',
    ENQUIRY_RESPONSE: 'ENQUIRY_RESPONSE',
    CALL_LOADER: 'CALL_LOADER',
    DELETE_ENQUIRY: 'DELETE_ENQUIRY',
}

export default actions;