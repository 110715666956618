import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import { getRequest, postRequest } from "helpers/axiosClient";
import { message } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import ListingLayoutAction from "./actions";
import Geocode from "react-geocode";
import { getLocaleMessages } from "redux/helper";

/*
6.6666004
-1.6162709
*/
//const defaultLocation = { lat: "6.6666004", lng: "-1.6162709" };
const defaultLocation = { lat: "5.6037", lng: "0.1870" };

export function* LoginListingSaloon(params) {
  try {
    const {
      latitude,
      longitude,
      sortby,
      service_available,
      userid,
      vendoraddress,
      categoryid,
      vendorlocation,
      searchdate,
      searchtime,
    } = params.payload;
    if (latitude == 0 && longitude === 0) {
      yield put({
        type: ListingLayoutAction.GET_CURRENT_LATLONG,
        payload: [
          {
            latitude: Number(defaultLocation?.lat),
            longitude: Number(defaultLocation?.lng),
          },
        ],
      });
      Geocode.fromLatLng(defaultLocation?.lat, defaultLocation?.lng).then(
        (response) => {
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          localStorage.setItem("formatted_address", city + state + country);
        },
        (error) => {
          localStorage.setItem("formatted_address", "");
        }
      );
    }
    var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
      latitude != 0 ? latitude : defaultLocation?.lat
    }&${getLocaleMessages({ id: "longitude" })}=${
      longitude != 0 ? longitude : defaultLocation?.lng
    }`;
    if (userid) {
      url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({ id: "userid" })}=${userid}`;
    }
    if (userid && sortby) {
      url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({ id: "userid" })}=${userid}&${getLocaleMessages({
        id: "sortby",
      })}=${sortby}`;
    }
    if (sortby) {
      url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({ id: "sortby" })}=${sortby}`;
    }
    if (service_available) {
      url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({ id: "service_available" })}=${service_available}`;
    }
    if (sortby && service_available) {
      url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "sortby",
      })}=${sortby}&${getLocaleMessages({
        id: "service_available",
      })}=${service_available}&${getLocaleMessages({
        id: "vendorlocation",
      })}=${vendorlocation}`;
    }
    if (userid && sortby && service_available) {
      url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "sortby",
      })}=${sortby}&${getLocaleMessages({
        id: "service_available",
      })}=${service_available}&${getLocaleMessages({
        id: "userid",
      })}=${userid}&${getLocaleMessages({
        id: "vendorlocation",
      })}=${vendorlocation}`;
    }
    if (vendoraddress) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({ id: "vendoraddress" })}=${vendoraddress}`;
    }
    if (vendoraddress && userid) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "vendoraddress",
      })}=${vendoraddress}&${getLocaleMessages({
        id: "userid",
      })}=${userid}`;
    }
    if (vendoraddress && userid && sortby) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "vendoraddress",
      })}=${vendoraddress}&${getLocaleMessages({
        id: "userid",
      })}=${userid}&${getLocaleMessages({ id: "sortby" })}=${sortby}`;
    }
    if (vendoraddress && sortby && service_available) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "vendoraddress",
      })}=${vendoraddress}&${getLocaleMessages({
        id: "userid",
      })}=${userid}&${getLocaleMessages({
        id: "sortby",
      })}=${sortby}&${getLocaleMessages({
        id: "service_available",
      })}=${service_available}`;
    }
    if (categoryid) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({ id: "categoryid" })}=${categoryid}`;
    }
    if (categoryid && userid) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "categoryid",
      })}=${categoryid}&${getLocaleMessages({
        id: "userid",
      })}=${userid}`;
    }
    if (categoryid && userid && sortby) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "categoryid",
      })}=${categoryid}&${getLocaleMessages({
        id: "userid",
      })}=${userid}&${getLocaleMessages({ id: "sortby" })}=${sortby}`;
    }
    if (categoryid && sortby && service_available) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "categoryid",
      })}=${categoryid}&${getLocaleMessages({
        id: "userid",
      })}=${userid}&${getLocaleMessages({
        id: "sortby",
      })}=${sortby}&${getLocaleMessages({
        id: "service_available",
      })}=${service_available}`;
    }
    if (categoryid && sortby && service_available && vendoraddress) {
      var url = `${getLocaleMessages({ id: "LoginListingSaloon" })}=${
        latitude != 0 ? latitude : defaultLocation?.lat
      }&${getLocaleMessages({ id: "longitude" })}=${
        longitude != 0 ? longitude : defaultLocation?.lng
      }&${getLocaleMessages({
        id: "categoryid",
      })}=${categoryid}&${getLocaleMessages({
        id: "userid",
      })}=${userid}&${getLocaleMessages({
        id: "sortby",
      })}=${sortby}&${getLocaleMessages({
        id: "service_available",
      })}=${service_available}&${getLocaleMessages({
        id: "vendoraddress",
      })}=${vendoraddress}`;
    }
    if ((searchdate && searchtime === undefined) || searchtime === "") {
      var url =
        url +
        `&${getLocaleMessages({
          id: "fromdate",
        })}=${searchdate}&${getLocaleMessages({ id: "todate" })}=${
          searchdate !== ""
            ? moment(searchdate).add(1, "d").format("YYYY-MM-DD")
            : ""
        }`;
    }
    if (searchdate && searchtime) {
      var url =
        url +
        `&${getLocaleMessages({
          id: "fromdate",
        })}=${searchdate} ${searchtime}&${getLocaleMessages({
          id: "todate",
        })}=${moment(searchdate)
          .add(1, "d")
          .format("YYYY-MM-DD")} ${searchtime}`;
    }
    const response = yield call(() => getRequest(url));

    yield put({
      type: actions.GET_LISTING_SALOON_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_LOGIN_LISTING_SALOON_FAILURE });
  }
}

export function* listingSaloonData(params) {
  try {
    const { latitude, longitude, sortby, service_available } = params.payload;
    var url = `${getLocaleMessages({
      id: "listingSaloonData",
    })}=${latitude}&${getLocaleMessages({ id: "longitude" })}=${longitude}`;
    if (sortby) {
      url = `${getLocaleMessages({
        id: "listingSaloonData",
      })}=${latitude}&${getLocaleMessages({
        id: "longitude",
      })}=${longitude}&${getLocaleMessages({ id: "sortby" })}=${sortby}`;
    }
    if (service_available) {
      url = `${getLocaleMessages({
        id: "listingSaloonData",
      })}=${latitude}&${getLocaleMessages({
        id: "longitude",
      })}=${longitude}&${getLocaleMessages({
        id: "service_available",
      })}=${service_available}`;
    }
    if (sortby && service_available) {
      url = `${getLocaleMessages({
        id: "listingSaloonData",
      })}=${latitude}&${getLocaleMessages({
        id: "longitude",
      })}=${longitude}&${getLocaleMessages({
        id: "sortby",
      })}=${sortby}&${getLocaleMessages({
        id: "service_available",
      })}=${service_available}`;
    }
    const response = yield call(() => getRequest(url));

    yield put({
      type: actions.GET_NEARBY_SALOON_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_NEARBY_SALOON_FAILURE });
  }
}

export function* saloonFavourite(params) {
  try {
    const response = yield call(() =>
      postRequest(getLocaleMessages({ id: "saloonFavourite" }), {
        ...params.payload,
      })
    );
    yield put({
      type: actions.SET_SALOON_FAVOURITE_SUCCESS,
      payload: params.payload.vendorid,
    });
    message.success(
      `Your Service is ${params.payload.favourite ? "Added" : "Removed"}`
    );
    if (response) {
      params.callBackAction(response);
    }
  } catch (error) {
    yield put({ type: actions.SET_SALOON_FAVOURITE_FAILURE });
  }
}

export function* categorysaloonDetail(params) {
  try {
    var URL = "";
    if (params.payload) {
      URL = `${getLocaleMessages({
        id: "categorysaloonDetail",
      })}=1&${getLocaleMessages({
        id: "categoryid",
      })}=${params.payload}`;
    } else {
      URL = `${getLocaleMessages({
        id: "categorysaloonDetail",
      })}=1`;
    }
    const response = yield call(() => getRequest(URL));
    yield put({
      type: actions.GET_CATEGORY_SALOON_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_CATEGORY_SALOON_DETAILS_FAILURE });
  }
}

export function* vendorbyname(params) {
  try {
    var URL = `${getLocaleMessages({ id: "vendorbyname" })}=${params.payload}`;
    const response = yield call(() => getRequest(URL));

    yield put({
      type: actions.GET_VENDOR_BY_NAME_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_VENDOR_BY_NAME_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NEARBY_SALOON, listingSaloonData),
    takeEvery(actions.GET_LOGIN_LISTING_SALOON, LoginListingSaloon),
    takeEvery(actions.SET_SALOON_FAVOURITE, saloonFavourite),
    takeEvery(actions.GET_CATEGORY_SALOON_DETAILS, categorysaloonDetail),
    takeEvery(actions.GET_VENDOR_BY_NAME, vendorbyname),
  ]);
}
