import React from "react";
import { Result } from "antd";
import { getLocaleMessages } from "redux/helper";

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status={getLocaleMessages({ id: "500" })}
          title={getLocaleMessages({ id: "Something went wrong" })}
          subTitle={getLocaleMessages({
            id: "Sorry, We will fix this as soon as possible.",
          })}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
