import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "helpers/axiosClient";
import swal from "sweetalert";
import { getLocalDataType, getLocaleMessages } from "redux/helper";
import { message } from "antd";

export function* postVedorCreate(params) {
  try {
    const response = yield call(() =>
      postRequest(getLocaleMessages({ id: "ADMIN_ADMINVENDOR_CREATE" }), {
        ...params.payload,
      })
    );

    if (response.data.code === 200) {
      swal({
        title: getLocaleMessages({ id: "Saloon Created" }),
        text: `${response.data.name}`,
        icon: "success",
        button: false,
        timer: 1500,
      });

      yield put({
        type: actions.GET_ALL_VENDOR,
      });

      yield put({
        type: actions.CREATE_VENDOR_PROFILE_SUCCESS,
      });
    } else {
      swal({
        title: getLocaleMessages({ id: "Saloon Error" }),
        text: `${response.data.name}`,
        icon: "info",
        button: false,
        timer: 1500,
      });
    }
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Saloon Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
    yield put({ type: actions.CREATE_VENDOR_PROFILE_FAILURE });
  }
}

export function* postVedorUpdate(params) {
  try {
    const response = yield call(() =>
      putRequest(getLocaleMessages({ id: "ADMIN_ADMINVENDOR_UPDATE" }), {
        ...params.payload,
      })
    );

    if (response.data.code === 200) {
      swal({
        title: getLocaleMessages({ id: "Saloon Updated" }),
        text: `${response.data.name}`,
        icon: "success",
        button: true,
      });
      if (getLocalDataType() == "admin") {
        yield put({
          type: actions.UPDATE_VENDOR_PROFILE_SUCCESS,
        });

        yield put({
          type: actions.GET_ALL_VENDOR,
        });
      } else if (getLocalDataType() == "vendor") {
        params.callBackAction(true);
      }
    }
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Saloon Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
    yield put({ type: actions.UPDATE_VENDOR_PROFILE_FAILURE });
  }
}

export function* getSingleVedor(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "ADMIN-ADMINVENDOR_BYID" })}=` + params.value
      )
    );

    yield put({
      type: actions.VENDOR_PROFILE_SUCCESS,
      payload: response.data.data,
    });
    if (response) {
      params.callBackAction(response);
    }
  } catch (error) {
    yield put({ type: actions.VENDOR_PROFILE_FAILURE });
  }
}

export function* acceptvendorReqeust(params) {
  try {
    const response = yield call(() =>
      postRequest(
        getLocaleMessages({ id: "ADMIN_PARTNER_APPROVAL" }),
        params.payload
      )
    );
    if (response.data) {
      message.success(response.data.name);
      params.callBackAction(true);
    }
  } catch (error) {
    params.callBackAction(false);
    message.error(
      `${error.response && error.response.data && error.response.data.message}`
    );
  }
}

export function* getCountryList(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "GET_COUNTRY_BYSTATUS" })}=` + params.value
      )
    );

    yield put({
      type: actions.COUNTRY_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.VENDOR_PROFILE_FAILURE });
  }
}

export function* getCityList(params) {
  try {
    yield put({
      type: actions.CITYLIST_LOADER,
    });
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "GET_CITY_BY_COUNTRYID" })}=` + params.value
      )
    );
    yield put({
      type: actions.CITY_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actions.CITYLIST_LOADER,
    });
    yield put({ type: actions.VENDOR_PROFILE_FAILURE });
  }
}

export function* getAreaList(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "GET_AREA_BY_CITYID" })}=` + params.value
      )
    );

    yield put({
      type: actions.AREA_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.VENDOR_PROFILE_FAILURE });
  }
}

export function* getDeleteList(params) {}

export function* getCategoryList(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "GET_CATEGORY_BY_ID" })}=` + params.value
      )
    );

    yield put({
      type: actions.CATEGORY_LIST_SUCCESS,
      payload: response.data.data,
    });
    if (params.callBackAction) {
      params.callBackAction(true);
    }
  } catch (error) {
    yield put({ type: actions.VENDOR_PROFILE_FAILURE });
  }
}

export function* deleteVenderData(params) {
  try {
    const response = yield call(() =>
      deleteRequest(
        `${getLocaleMessages({ id: "ADMIN_VENDOR_DELETE" })}=` + params.id
      )
    );

    swal({
      title: getLocaleMessages({
        id: "Your Service details  has been successfully deleted",
      }),
      text: `${response.data.name}`,
      icon: "success",
      button: false,
      timer: 1500,
    });
    yield put({
      type: actions.GET_ALL_VENDOR_NEW,
    });
  } catch (error) {
    yield put({ type: actions.VENDOR_DELETE_FAILURE });
  }
}

export function* getAssignedSaloonData(params) {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "ADMIN_VENDOR_GETALL" }))
    );
    yield put({
      type: actions.GET_ALL_VENDOR_RESPONSE,
      payload: response.data,
    });
  } catch (error) {}
}

export function* getAssignedSaloonDatanew(params) {
  try {
    yield put({
      type: actions.CHANGE_DELETE_LOADER,
    });
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "ADMIN_VENDOR_GETALL_SALOON" }))
    );
    yield put({
      type: actions.GET_ALL_VENDOR_RESPONSE_NEW,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: actions.CHANGE_DELETE_LOADER,
    });
  }
}

export function* getEmailVeirification(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "GET_PARTNER_BYID" })}=${
          params.payload.vendorId
        }&email=${params.payload.email}`
      )
    );

    if (response.data.code === 200) {
      swal({
        title: getLocaleMessages({ id: "Verification Link sent to Email" }),
        text: getLocaleMessages({
          id: "Please click the Link to verify Email",
        }),
        icon: "success",
        timer: 1500,
      });

      yield put({
        type: actions.EMAIL_VERIFIED_SUCCESS,
      });
      yield put({
        type: actions.GET_ALL_VENDOR,
      });
    } else {
      swal({
        title: getLocaleMessages({ id: "Saloon Error" }),
        text: `${response.data.name}`,
        icon: "info",
        button: false,
        timer: 1500,
      });
    }
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Saloon Error" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
    yield put({ type: actions.EMAIL_VERIFIED_FAILURE });
  }
}

export function* postOTPVerification(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "GET_PARTNER_OTP" })}=${
          params.payload.vendorId
        }&contactnumber=${params.payload.contactnumber}&countrycode=${
          params.payload.countrycode
        }`
      )
    );

    if (response.data.code === 200) {
      yield put({
        type: actions.OTP_VERIFIED_SUCCESS,
        payload: response.data.data,
      });
    } else {
      swal({
        title: getLocaleMessages({ id: "Otp verified success" }),
        text: `${response.data.name}`,
        icon: "info",
        button: false,
        timer: 1500,
      });
    }
  } catch (error) {
    swal({
      title: getLocaleMessages({ id: "Otp verified failed" }),
      text: `Invalid otp`,
      icon: "error",
      button: true,
    });
    yield put({ type: actions.OTP_VERIFIED_FAILURE });
  }
}

export function* putOTPVerification(params) {
  try {
    const response = yield call(() =>
      putRequest(
        getLocaleMessages({ id: "VERIFY_PARTNER_OTP" }),
        params.payload
      )
    );

    if (response.data.code === 200) {
      swal({
        title: getLocaleMessages({ id: "Otp verified success" }),
        text: `${response.data.name}`,
        icon: "success",
        button: false,
        timer: 1500,
      });
      yield put({
        type: actions.PATNER_VERIFY_OTP_SUCCESS,
        payload: response.data.data,
      });
      params.callBackAction(true);
    } else {
      swal({
        title: getLocaleMessages({ id: "Otp verified failed" }),
        icon: "info",
        button: false,
        timer: 1500,
      });
    }
  } catch (error) {
    params.callBackAction(false);
    swal({
      title: getLocaleMessages({ id: "Otp verified failed" }),
      text: `${
        error.response && error.response.data && error.response.data.message
      }`,
      icon: "error",
      button: true,
    });
    yield put({ type: actions.PATNER_VERIFY_OTP_FAILURE });
  }
}

export function* get_notifications(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "NOTIFICATIONS_BY_USERID" })}=${
          params.payload.userid
        }&usertype=${params.payload.usertype}`
      )
    );
    if (response.data) {
      yield put({
        type: actions.GET_ALL_NOTIFICATION_SUCCESS,
        payload: response.data,
      });
    }
    params.callBackAction(response.data);
  } catch (error) {
    yield put({ type: actions.GET_ALL_NOTIFICATION_FAILURE });
  }
}

export function* get_clearnotifications(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({
          id: "NOTIFICATION_COUNT",
        })}=${params.payload.userid}&usertype=${params.payload.usertype}`
      )
    );
    if (response) {
      yield put({
        type: actions.CLEAR_ALL_NOTIFICATION_SUCCESS,
      });
    }
  } catch (error) {
    yield put({ type: actions.CLEAR_ALL_NOTIFICATION_FAILURE });
  }
}

export function* changeVendorPassword(params) {
  try {
    const response = yield call(() =>
      putRequest(getLocaleMessages({ id: "VENDOR_CHANGE_PASSWORD" }), {
        ...params.payload,
      })
    );
    if (response.status === 200) {
      yield put({
        type: actions.VENDOR_PASSWORD_CHANGE_SUCCESS,
      });
      message.success(
        getLocaleMessages({ id: "Password Updated successfully" })
      );
    }
    if (params.callBackAction) {
      params.callBackAction(true);
    }
  } catch (error) {
    if (params.callBackAction) {
      params.callBackAction(false);
    }
    message.error(error.response?.data?.message);

    yield put({
      type: actions.VENDOR_PASSWORD_CHANGE_FAILURE,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_VENDOR_PROFILE, postVedorCreate),
    takeEvery(actions.UPDATE_VENDOR_PROFILE, postVedorUpdate),
    takeEvery(actions.GET_SINGLE_VENDOR, getSingleVedor),
    takeEvery(actions.GET_ALL_VENDOR, getAssignedSaloonData),
    takeEvery(actions.GET_ALL_VENDOR_NEW, getAssignedSaloonDatanew),
    takeEvery(actions.VENDOR_DELETE, deleteVenderData),
    takeEvery(actions.COUNTRY_LIST, getCountryList),
    takeEvery(actions.CITY_LIST, getCityList),
    takeEvery(actions.AREA_LIST, getAreaList),
    takeEvery(actions.CATEGORY_LIST, getCategoryList),
    takeEvery(actions.SALOON_VENDOR_DELETE, getDeleteList),
    takeEvery(actions.ACCEPT_VENDOR_REQUEST, acceptvendorReqeust),
    takeEvery(actions.OTP_VERIFIED, postOTPVerification),
    takeEvery(actions.EMAIL_VERIFIED, getEmailVeirification),
    takeEvery(actions.PATNER_VERIFY_OTP, putOTPVerification),
    takeEvery(actions.GET_ALL_NOTIFICATION, get_notifications),
    takeEvery(actions.CLEAR_ALL_NOTIFICATION, get_clearnotifications),
    takeEvery(actions.VENDOR_PASSWORD_CHANGE, changeVendorPassword),
  ]);
}
