import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import { getRequest, postRequest } from "helpers/axiosClient";
import { getLocaleMessages } from "redux/helper";

export function* layoutCategory() {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "layoutCategory" }))
    );
    yield put({
      type: actions.GET_LAYOUT_CATEGORIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_LAYOUT_CATEGORIES_FAILURE });
  }
}

export function* layoutSocialinfo() {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "layoutSocialinfo" }))
    );

    yield put({
      type: actions.GET_SOCIAL_DETAILS_RES,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_LAYOUT_CATEGORIES_FAILURE });
  }
}

export function* layoutSaloon() {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "layoutSaloon" }))
    );
    yield put({
      type: actions.GET_LAYOUT_SALOON_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_LAYOUT_SALOON_FAILURE });
  }
}

export function* layoutTopRating() {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "layoutTopRating" }))
    );
    yield put({
      type: actions.GET_LAYOUT_TOP_RATING_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_LAYOUT_SALOON_FAILURE });
  }
}

export function* layoutCMSlist() {
  try {
    const response = yield call(() =>
      getRequest(getLocaleMessages({ id: "layoutCMSlist" }))
    );
    yield put({
      type: actions.GET_CMS_DETAILS_RES,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_LAYOUT_CATEGORIES_FAILURE });
  }
}

export function* saveEnquiry(params) {
  try {
    const response = yield call(() =>
      postRequest(getLocaleMessages({ id: "saveEnquiry" }), params.payload)
    );
    params.callBackAction1(response.status);
  } catch (error) {
    yield put({ type: actions.GET_LAYOUT_CATEGORIES_FAILURE });
  }
}

export function* getSaloonByName(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "getSaloonByName" })}=${params.name}`
      )
    );
    yield put({
      type: actions.GET_SALOON_BY_NAME_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_SALOON_BY_NAME_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LAYOUT_TOP_RATING, layoutTopRating),
    takeEvery(actions.GET_LAYOUT_SALOON, layoutSaloon),
    takeEvery(actions.GET_LAYOUT_CATEGORIES, layoutCategory),
    takeEvery(actions.GET_FOOTER_DETAILS, layoutSocialinfo),
    takeEvery(actions.GET_CMS_DETAILS, layoutCMSlist),
    takeEvery(actions.SAVE_ENQUIRY, saveEnquiry),
    takeEvery(actions.GET_SALOON_BY_NAME, getSaloonByName),
  ]);
}
