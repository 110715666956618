const actions = {
  UPDATE_SMTP: "UPDATE_SMTP",
  UPDATE_SMTP_RESPONSE: "UPDATE_SMTP_RESPONSE",
  GET_SMTP_DETAILS: "GET_SMTP_DETAILS",
  GET_SMTP_DETAILS_RESPONSE: "GET_SMTP_DETAILS_RESPONSE",

  GET_SMS_DETAILS: "GET_SMS_DETAILS",
  GET_SMS_DETAILS_RESPONSE: "GET_SMS_DETAILS_RESPONSE",
  UPDATE_SMS: "UPDATE_SMS",
  SEND_SMS: "SEND_SMS",
  UPDATE_SMS_RESPONSE: "UPDATE_SMS_RESPONSE",

  GET_SOCIAL_DETAILS: "GET_SOCIAL_DETAILS",
  GET_SOCIAL_DETAILS_RESPONSE: "GET_SOCIAL_DETAILS_RESPONSE",
  UPDATE_SOCIAL_MEDIA: "UPDATE_SOCIAL_MEDIA",
  UPDATE_SOCIAL_MEDIA_RESPONSE: "UPDATE_SOCIAL_MEDIA_RESPONSE",

  UPDATE_CONFIG: "UPDATE_CONFIG",
  GET_APPCONFIG: "GET_APPCONFIG",
  GET_APPCONFIG_RESPONSE: "GET_APPCONFIG_RESPONSE",
  UPLOAD_SITEIMAGE_RESPONSE: "UPLOAD_SITEIMAGE_RESPONSE",
  UPLOAD_SITEDOC: "UPLOAD_SITEDOC",
  UPLOAD_SITEDOC_RESPONSE: "UPLOAD_SITEDOC_RESPONSE",

  SHOW_LOADER: "SHOW_LOADER",
  UPDATE_CHECKED: "UPDATE_CHECKED",

  GET_ADMIN_RATINGS: "GET_ADMIN_RATINGS",
  GET_ADMIN_RATINGS_FILTER: "GET_ADMIN_RATINGS_FILTER",
  GET_ADMIN_RATING_DETAILS_RESPONSE: "GET_ADMIN_RATING_DETAILS_RESPONSE",
  DELETE_RATING: "DELETE_RATING",
  UNAPPROVE_RATING: "UNAPPROVE_RATING",
  APPROVE_RATING: "APPROVE_RATING",
  ADMIN_UNAPPROVE_RATING: "ADMIN_UNAPPROVE_RATING",
  ADMIN_APPROVE_RATING: "ADMIN_APPROVE_RATING",
};

export default actions;
