import React from "react";
import { Modal, Input, Form, Button } from "antd";
import { FacebookFilled, GoogleOutlined } from "@ant-design/icons";
import { getLocaleMessages } from "redux/helper";

const SigninModal = (props) => {
  const {
    visible,
    onOk,
    onCancel,
    onLoginForgot,
    onFinish,
    LoginSignup,
    loader,
  } = props;
  return (
    <Modal
      title={false}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      centered
      footer={false}
      className="modal-ui-1"
      width="100%"
      destroyOnClose
    >
      <div className="modal-body-ui">
        <h2>{getLocaleMessages({ id: "label.signIn" })}</h2>
        <Form name="normal_login" className="login-form" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                whitespace: true,
                message: getLocaleMessages({
                  id: "Please input your email / mobile no!",
                }),
              },
            ]}
          >
            <Input
              placeholder={getLocaleMessages({ id: "Email / Mobile No" })}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                whitespace: true,
                message: getLocaleMessages({
                  id: "Please input your Password!",
                }),
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder={getLocaleMessages({ id: "Password" })}
            />
          </Form.Item>
          <div className="text-right forgotLink">
            <div
              onClick={() =>
                loader ? "" : onLoginForgot({ login: false, forgot: true })
              }
            >
              {getLocaleMessages({ id: "Forgot" })}?
            </div>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loader}
            >
              {getLocaleMessages({ id: "Sign In" })}
            </Button>
          </Form.Item>
        </Form>
        <span className="or">({getLocaleMessages({ id: "OR" })})</span>
        <div className="contactsocial">
          <span className="btns">
            <FacebookFilled />
          </span>
          <span className="btns">
            <GoogleOutlined />
          </span>
        </div>
        <p className="new">
          {getLocaleMessages({ id: "New around here?" })}{" "}
          <span
            onClick={() =>
              loader ? "" : LoginSignup({ login: false, signup: true })
            }
          >
            {getLocaleMessages({ id: "Sign up" })}
          </span>{" "}
        </p>
      </div>
    </Modal>
  );
};

export default SigninModal;
