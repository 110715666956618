import { all, put, call, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import { putRequest, uploadRequest, getRequest } from "helpers/axiosClient";
import { message } from "antd";
import { getLocaleMessages } from "redux/helper";

export function* get_notification(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "get_notification" })}=${params.payload.id}`
      )
    );
    if (response.data) {
      yield put({
        type: actions.GET_NOTIFICATION_SUCCESS,
        payload: response.data.data,
      });
      getRequest(
        `${getLocaleMessages({ id: "common_notification" })}=${
          params.payload.id
        }`
      );
    }
  } catch (error) {
    yield put({ type: actions.GET_NOTIFICATION_FAILURE });
  }
}

export function* set_notification(params) {
  try {
    const response = yield call(() =>
      getRequest(
        `${getLocaleMessages({ id: "set_notification" })}=${params.payload.id}`
      )
    );
    if (response.data) {
      yield put({
        type: actions.SET_NOTIFICATION_SUCCESS,
      });
    }
  } catch (error) {
    yield put({ type: actions.SET_NOTIFICATION_FAILURE });
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NOTIFICATION, get_notification),
    takeEvery(actions.SET_NOTIFICATION, set_notification),
  ]);
}
