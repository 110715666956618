import React, { useEffect } from "react";
import { Layout, Menu } from "antd";
import menus from "containers/Sidebar/menus";
import SidebarMenu from "components/Sidebar/SidebarMenu";
import { useDispatch, useSelector } from "react-redux";
import actions from "redux/app/actions";
import StickyBox from "react-sticky-box";
import { store, history } from "redux/store";
import {
  SettingOutlined,
  UserAddOutlined,
  CreditCardOutlined,
  ExceptionOutlined,
  ToolOutlined,
  ScheduleOutlined,
  RightOutlined,
  CalendarOutlined,
  UserOutlined,
  ContactsOutlined,
  MessageOutlined,
  StarOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { getLocaleMessages } from "redux/helper";
const { SubMenu } = Menu;
const { Sider } = Layout;

function Sidebar() {
  const { currentKey } = useSelector((state) => state.App),
    dispatch = useDispatch();

  useEffect(() => {
    store.dispatch({
      type: actions.CHANGE_CURRENT_MENU,
      payload: [
        history.location.pathname.toLowerCase().substring(1).replace("/", "_"),
      ],
    });
  }, [history.location.pathname]);

  const handleClick = (changedKey) => {
    dispatch({ type: actions.CHANGE_CURRENT_MENU, payload: [changedKey.key] });
  };
  return (
    <>
      <Sider className="sidebar" width={250}>
        <StickyBox offsetTop={80} offsetBottom={20}>
          <Menu
            theme="light"
            selectedKeys={currentKey}
            onClick={handleClick}
            mode="inline"
          >
            {menus.map((singleOption) => (
              <SidebarMenu key={singleOption.key} singleOption={singleOption} />
            ))}

            <Menu.Item key="admin_calendar">
              <ExceptionOutlined />
              <NavLink to={"/admin/calendar"}>
                {getLocaleMessages({ id: "Calendar" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_bookingmanagement">
              <CalendarOutlined />
              <NavLink to={"/admin/bookingmanagement"}>
                {getLocaleMessages({ id: "Booking" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_services">
              <ExceptionOutlined />
              <NavLink to={"/admin/services"}>
                {" "}
                {getLocaleMessages({ id: "Service Menu" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_category">
              <ExceptionOutlined />
              <NavLink to={"/admin/category"}>
                {" "}
                {getLocaleMessages({ id: "Admin Menu" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_staffmanagement">
              <ScheduleOutlined />
              <NavLink to={"/admin/stafmanagement"}>
                {" "}
                {getLocaleMessages({ id: "Staff" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_vouchermanagement">
              <CreditCardOutlined />
              <NavLink to={"/admin/vouchermanagement"}>
                {getLocaleMessages({ id: "Voucher" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_vendor">
              <ExceptionOutlined />
              <NavLink to={"/admin/vendor"}>
                {getLocaleMessages({ id: "Service" })}
              </NavLink>{" "}
            </Menu.Item>
            <Menu.Item key="admin_customer">
              <UserOutlined />
              <NavLink to={"/admin/customer"}>
                {getLocaleMessages({ id: "Customer" })}
              </NavLink>
            </Menu.Item>
            <SubMenu
              key="admin_city"
              icon={<ContactsOutlined />}
              title="Address"
            >
              <Menu.Item key="city">
                <RightOutlined />
                <NavLink to={"/admin/city"}>
                  {getLocaleMessages({ id: "City" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin_area">
                <RightOutlined />
                <NavLink to={"/admin/area"}>
                  {getLocaleMessages({ id: "Area" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin_country">
                <RightOutlined />
                <NavLink to={"/admin/country"}>
                  {getLocaleMessages({ id: "Country" })}
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="admin_rnquirymanagement">
              <MessageOutlined />
              <NavLink to={"/admin/enquirymanagement"}>
                {getLocaleMessages({ id: "Enquiry" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_ratingandreview">
              <StarOutlined />
              <NavLink to={"/admin/ratingandreview"}>
                {" "}
                {getLocaleMessages({ id: "Rating And Review" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin-activelog">
              <InteractionOutlined />
              <NavLink to={"/admin/activelog"}>
                {" "}
                {getLocaleMessages({ id: "Activity Log" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_roles">
              <ToolOutlined />
              <NavLink to={"/admin/roles"}>
                {getLocaleMessages({ id: "Roles" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_administrator">
              <UserAddOutlined />
              <NavLink to={"/admin/administrator"}>
                {getLocaleMessages({ id: "Administrator" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_profile">
              <UserAddOutlined />
              <NavLink to={"/admin/profile"}>
                {getLocaleMessages({ id: "Profile" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_settings">
              <UserAddOutlined />
              <NavLink to={"/admin/settings"}>
                {getLocaleMessages({ id: "Commission" })}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="admin_report">
              <UserAddOutlined />
              <NavLink to={"/admin/report"}>
                {getLocaleMessages({ id: "Report" })}
              </NavLink>
            </Menu.Item>
            <SubMenu
              key="sub1"
              icon={<SettingOutlined />}
              title={getLocaleMessages({ id: "Settings" })}
            >
              <Menu.Item key="admin_appconfiguration">
                <RightOutlined />
                <NavLink to={"/admin/appconfiguration"}>
                  {getLocaleMessages({ id: "Application Configuration" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin_socialmedia">
                <RightOutlined />
                <NavLink to={"/admin/socialmedia"}>
                  {getLocaleMessages({ id: "Social Media" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin_sms">
                <RightOutlined />
                <NavLink to={"/admin/sms"}>
                  {getLocaleMessages({ id: "SMS" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin_smtp">
                <RightOutlined />
                <NavLink to={"/admin/smtp"}>
                  {getLocaleMessages({ id: "SMTP" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin_ushnotification">
                <RightOutlined />
                <NavLink to={"/admin/pushnotification"}>
                  {getLocaleMessages({ id: "Push Notification" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin/_cms">
                <RightOutlined />
                <NavLink to={"/admin/cms"}>
                  {getLocaleMessages({ id: "CMS" })}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin_faq">
                <RightOutlined />
                <NavLink to={"/admin/faq"}>
                  {getLocaleMessages({ id: "FAQ" })}
                </NavLink>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </StickyBox>
      </Sider>
    </>
  );
}

export default Sidebar;
