const actions = {
  GET_ROLES: "GET_ROLES",
  GET_ONLY_ROLES: "GET_ONLY_ROLES",
  GET_ONLY_ROLES_RESPONSE: "GET_ONLY_ROLES_RESPONSE",
  GET_ONLY_SINGLEROLE_RESPONSE: "GET_ONLY_SINGLEROLE_RESPONSE",
  GET_ROLES_RESPONSE: "GET_ROLES_RESPONSE",
  CREATE_ROLE: "CREATE_ROLE",
  CREATE_ROLE_JS: "CREATE_ROLE_JS",
  CALL_ROLES_LOADER: "CALL_ROLES_LOADER",

  CREATE_ROLE_RESPONSE: "CREATE_ROLE_RESPONSE",
  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_ROLE_RESPONSE: "UPDATE_ROLE_RESPONSE",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",
  GET_SINGLE_ROLES: "GET_SINGLE_ROLES",
  DELETE_ROLE_RESPONSE: "DELETE_ROLE_RESPONSE",
  UPDATE_PARENT_CHECK: "UPDATE_PARENT_CHECK",
  UPDATE_CHILD_CHECK: "UPDATE_CHILD_CHECK",
  ASSIGNED_ROLES_RESPONSE: "ASSIGNED_ROLES_RESPONSE",
  GET_ASSIGNED_ROLES: "GET_ASSIGNED_ROLES",
  ROLENAME: "ROLENAME",
  ROLESTATUS: "ROLESTATUS",
  UPDATE_RESPONSE: "UPDATE_RESPONSE",
  ROLES_REDIRECT: "ROLES_REDIRECT",
  STATUS_ROLE: "STATUS_ROLE",
};

export default actions;
