const actions = {
  POST_STAFF: "POST_STAFF",
  GET_ADMIN_STAFF_LIST: "GET_ADMIN_STAFF_LIST",
  STAFF_LIST_RESPONSE: "STAFF_LIST_RESPONSE",
  GET_STAFF: "GET_STAFF",
  STAFF_RESPONSE: "STAFF_RESPONSE",
  CALL_LOADER: "CALL_LOADER",
  CALL_STAFF_LOADER: "CALL_STAFF_LOADER",
  CALL_STAFF_TIME_LOADER: "CALL_STAFF_TIME_LOADER",
  CALL_STAFF_SUCESS: "CALL_STAFF_SUCESS",
  DELETE_STAFF: "DELETE_STAFF",
  CALL_STAFF_UPDATE_SUCESS: "CALL_STAFF_UPDATE_SUCESS",
  GET_DELETE_STAFF_LIST_RESPONSE: "GET_DELETE_STAFF_LIST_RESPONSE",
  PUT_STAFF_TIME: "PUT_STAFF_TIME",
  PUT_STAFF_TIME_RES: "PUT_STAFF_TIME_RES",
  PUT_STAFF_STATUS: "PUT_STAFF_STATUS",
  GET_ADMIN_STAFF_TIMELIST: "GET_ADMIN_STAFF_TIMELIST",
  STAFFTIME_LIST_RESPONSE: "STAFFTIME_LIST_RESPONSE",
  LOAD_POPUP_SLOT: "LOAD_POPUP_SLOT",
  GET_ADMIN_STAFF_SHIFT_FULL_LIST: "GET_ADMIN_STAFF_SHIFT_FULL_LIST",
  GET_ADMIN_STAFF_SHIFT_FULL_LIST_SUCCESS:
    "GET_ADMIN_STAFF_SHIFT_FULL_LIST_SUCCESS",
  GET_ADMIN_STAFF_SHIFT_FULL_LIST_FAILURE:
    "GET_ADMIN_STAFF_SHIFT_FULL_LIST_FAILURE",
  GET_ADMIN_STAFF_SHIFT_LIST: "GET_ADMIN_STAFF_SHIFT_LIST",
  GET_ADMIN_STAFF_SHIFT_LIST_SUCCESS: "GET_ADMIN_STAFF_SHIFT_LIST_SUCCESS",
  GET_ADMIN_STAFF_SHIFT_LIST_FAILURE: "GET_ADMIN_STAFF_SHIFT_LIST_FAILURE",
  UPDATE_ADMIN_STAFF_SHIFT: "UPDATE_ADMIN_STAFF_SHIFT",
  UPDATE_ADMIN_STAFF_SHIFT_SUCCESS: "UPDATE_ADMIN_STAFF_SHIFT_SUCCESS",
  UPDATE_ADMIN_STAFF_SHIFT_FAILURE: "UPDATE_ADMIN_STAFF_SHIFT_FAILURE",
  DELETE_ADMIN_STAFF_SHIFT: "DELETE_ADMIN_STAFF_SHIFT",
  DELETE_ADMIN_STAFF_SHIFT_SUCCESS: "DELETE_ADMIN_STAFF_SHIFT_SUCCESS",
  DELETE_ADMIN_STAFF_SHIFT_FAILURE: "DELETE_ADMIN_STAFF_SHIFT_FAILURE",
  CREATE_ADMIN_STAFF_SHIFT: "CREATE_ADMIN_STAFF_SHIFT",
  CREATE_ADMIN_STAFF_SHIFT_SUCCESS: "CREATE_ADMIN_STAFF_SHIFT_SUCCESS",
  CREATE_ADMIN_STAFF_SHIFT_FAILURE: "CREATE_ADMIN_STAFF_SHIFT_FAILURE",
};

export default actions;
