const actions = {
    GET_LAYOUT_CATEGORIES: 'GET_LAYOUT_CATEGORIES',
    GET_LAYOUT_CATEGORIES_SUCCESS: 'GET_LAYOUT_CATEGORIES_SUCCESS',
    GET_LAYOUT_CATEGORIES_FAILURE: 'GET_LAYOUT_CATEGORIES_FAILURE',
    GET_LAYOUT_SALOON: 'GET_LAYOUT_SALOON',
    GET_LAYOUT_SALOON_SUCCESS: 'GET_LAYOUT_SALOON_SUCCESS',
    GET_LAYOUT_SALOON_FAILURE: 'GET_LAYOUT_SALOON_FAILURE',
    GET_LAYOUT_TOP_RATING: 'GET_LAYOUT_TOP_RATING',
    GET_LAYOUT_TOP_RATING_SUCCESS: 'GET_LAYOUT_TOP_RATING_SUCCESS',
    GET_LAYOUT_TOP_RATING_FAILURE: 'GET_LAYOUT_TOP_RATING_FAILURE',
    GET_APP_CONFIG: 'GET_APP_CONFIG',
    GET_SOCIAL_DETAILS: 'GET_SOCIAL_DETAILS',
    GET_SOCIAL_DETAILS_RES: 'GET_SOCIAL_DETAILS_RES',
    GET_CMS_DETAILS:'GET_CMS_DETAILS',
    GET_CMS_DETAILS_RES:'GET_CMS_DETAILS_RES',
    GET_FOOTER_DETAILS : 'GET_FOOTER_DETAILS',
    SAVE_ENQUIRY : 'SAVE_ENQUIRY',
    GET_SALOON_BY_NAME: 'GET_SALOON_BY_NAME',
    GET_SALOON_BY_NAME_SUCCESS: 'GET_SALOON_BY_NAME_SUCCESS',
    GET_SALOON_BY_NAME_FAILURE: 'GET_SALOON_BY_NAME_FAILURE',
    COUNTRY_LIST: 'COUNTRY_LIST',
    COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
    CITY_LIST: 'CITY_LIST',
    CITY_LIST_SUCCESS: 'CITY_LIST_SUCCESS',
};
  
export default actions;
  