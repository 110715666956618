const actions = {
    GET_COMMISSION_LIST: 'GET_COMMISSION_LIST',
    GET_COMMISSION_LIST_SUCCESS: 'GET_COMMISSION_LIST_SUCCESS',
    GET_COMMISSION_LIST_FAILURE: 'GET_COMMISSION_LIST_FAILURE',

    NEW_COMMISSION: 'NEW_COMMISSION',
    NEW_COMMISSION_SUCCESS: 'NEW_COMMISSION_SUCCESS',
    NEW_COMMISSION_FAILURE: 'NEW_COMMISSION_FAILURE',
}

export default actions;